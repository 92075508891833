import { formatDistance } from 'date-fns'
import { enUS, de, fr, it } from 'date-fns/locale'
import { doc, getDoc, Timestamp } from 'firebase/firestore'
import { getStorage, getDownloadURL, ref as storageRef } from 'firebase/storage'
import { CameraRoll, HydratedPhoto, MasonryRow, PhotoDisplayGroup, PhotoDisplayGroupMasonryRows } from '~~/types/types'

export function useDistanceText(date: Date, locale: string) {
  const localeMap = {
    en: enUS,
    de: de,
    fr: fr,
    it: it,
  }
  const trimmedLocale = locale.substring(0, 2)

  if (!Object.keys(localeMap).includes(trimmedLocale)) {
    console.error(`Invalid Locale ${trimmedLocale}, fallimg back to "en"`)
    locale = 'en'
  }
  return formatDistance(date, new Date(), {
    locale: localeMap[trimmedLocale as keyof typeof localeMap],
    addSuffix: true,
  })
}

/**
 * Group a list of photos in groups by hours, but making sure
 * that each group has at least minPhotosPerGroup.
 * Used for displaying.
 * @param photos
 * @param minPhotosPerGroup
 * @returns
 */
export function useGroupByHour(
  photos: { [id: string]: HydratedPhoto },
  minPhotosPerGroup: number,
  refDate: Date
): PhotoDisplayGroup[] {
  const getPhotoDate = (dateOrTimestamp: Date | Timestamp): number => {
    if (dateOrTimestamp instanceof Timestamp) {
      return dateOrTimestamp.toDate().getTime()
    } else {
      return dateOrTimestamp.getTime()
    }
  }
  // Sort photos by time in reverse order
  const m = Object.keys(photos)
    .sort()
    .reverse()
    .reduce((prev, cur) => {
        prev.set(cur, getPhotoDate(photos[cur].createdAt))
        return prev;
      }, new Map());

  const timestampToPhotoId = new Map([...m].sort((a, b) => b[1] - a[1]));

  // Get the timestamp of the most recent photo
  const newRefDate = new Date([...timestampToPhotoId.values()][0])

  const msPerHour = 3600 * 1000 // NOSONAR
  const msPerDay = msPerHour * 24 // NOSONAR

  function dateQuantizedByHour(date: Date) {
    const deltaHours = Math.floor((newRefDate.getTime() - date.getTime()) / msPerHour)
    const d = new Date(newRefDate)
    d.setHours(newRefDate.getHours() - deltaHours)
    return d
  }

  function dateQuantizedByDay(date: Date) {
    const deltaDays = Math.floor((newRefDate.getTime() - date.getTime()) / msPerDay)
    const d = new Date(newRefDate)
    d.setDate(newRefDate.getDate() - deltaDays)
    return d
  }

  let prevHour: Date | null = null
  let currentGroup: PhotoDisplayGroup
  let curHour
  const result = [] as PhotoDisplayGroup[]
  timestampToPhotoId.forEach((val, key: string) => {
    // Quantize the date to be close to one hour
    if ((refDate.getTime() - new Date(val).getTime()) / msPerDay > 1) {
      curHour = dateQuantizedByDay(new Date(val))
    } else {
      curHour = dateQuantizedByHour(new Date(val))
    }
    if (!currentGroup) {
      currentGroup = {
        refDate: new Date(curHour),
        photoIds: [key],
      }
    } else {
      if (curHour.getTime() !== prevHour?.getTime() && currentGroup.photoIds.length >= minPhotosPerGroup) {
        result.push(currentGroup)
        currentGroup = {
          refDate: new Date(curHour),
          photoIds: [key],
        }
      } else {
        currentGroup.photoIds.push(key)
      }
    }
    prevHour = new Date(curHour)
  })
  if (currentGroup!) {
    result.push(currentGroup)
  }

  return result
}

/**
 * The algo is describe in the following sheet:
 * https://docs.google.com/spreadsheets/d/14nJcQpEdY2D7I3cVpatzgS1PRCmphWgesKJGDQQytVI/edit?usp=sharing
 */
export function useGenerateGalleryMasonry(
  group: PhotoDisplayGroup,
  startOpposite = false
): PhotoDisplayGroupMasonryRows {
  let result: PhotoDisplayGroupMasonryRows = {
    refDate: group.refDate,
    masonryRows: [],
  }
  const rows = Math.ceil(group.photoIds.length / 3)
  for (let i = 0; i < rows; i++) {
    result.masonryRows.push({
      type: (() => {
        const j = startOpposite ? i + 2 : i
        if (j % 4 == 1 && rows > 2 && j < rows - 2) {
          return 'large_left'
        } else if (j % 4 == 3 && rows > 2 && j < rows - 2) {
          return 'large_right'
        } else {
          return 'normal'
        }
      })(),
      photoIds: group.photoIds.slice(i * 3, i * 3 + 3),
    } as MasonryRow)
  }
  return result
}

export function useGetPhotoUrl(prefix: string, filename: string, filetype: string) {
  const config = useRuntimeConfig()
  
  const prefixedFilename = `${prefix}${filename}`

  if (filetype == "blob") {
    return filename
  }

  const storage = getStorage()
  const photoRef = storageRef(storage, `gs://${config.public.storageBucket}/photos/${prefixedFilename}`)

  return getDownloadURL(photoRef)
    .then((r) => {
      return r
    })
    .catch(() => {
      if (prefix) {
        const fallbackFilename = `${filename}`
        const photoRef = storageRef(storage, `gs://${config.public.storageBucket}/photos/${fallbackFilename}`)
        return getDownloadURL(photoRef).then((r) => {
          return r
        })
      }
    })
}
