<template>
  <div class="skeleton">
    <slot />
  </div>
</template>

<style scoped>
.skeleton {
  @apply relative overflow-hidden;
}

@keyframes skeleton-animation {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.skeleton::after {
  @apply absolute h-full inset-0;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
  animation: skeleton-animation 1.2s infinite;
  content: '';
}
</style>
