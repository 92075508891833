<script setup lang="ts">
import { getFunctions, httpsCallable } from '@firebase/functions'
import { onUnmounted, ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLoading } from 'vue-loading-overlay'
import { useToast } from 'vue-toastification'
import { useLoginStore } from '~~/store/login'
import { useUserStore } from '~~/store/user'
import * as EmailValidator from 'email-validator'
import {useUserPublicByEmail} from "~/composables/useUserEdit";
import {UserStates} from "~/types/enums";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";

/**
 * Displays a message to the user that he/she is not allowed to upload to the camera-roll
 * unless he/she enters her/his name ore email address.
 */

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  requireEmail: {
    type: Boolean,
    default: false,
  },
  prevent: {
    type: String,
    default: 'upload',
  },
  targetpath: {
    type: String,
    default: '',
  },
})

const i18n = useI18n()
const toast = useToast()
const loading = useLoading()
const router = useRouter()

const name = ref('')
const email = ref('')
const validationEnabled = ref(false)

const formFieldElement = ref(null)
const showExplanation = ref(true)
let observer: IntersectionObserver | null = null

const userStore = useUserStore()
const loginStore = useLoginStore()
const { userState, isGuest } = storeToRefs(userStore)

const emits = defineEmits(['close', 'submit'])

function validate() {
  validationEnabled.value = true
  return nameIsValid.value && emailIsValid.value
}

async function submitForm() {
  const formIsValid = validate()
  if (formIsValid) {
    let loader
    try {
      if (email.value) {
        const user = await useUserPublicByEmail(email.value)
        if (user && email.value) {
          // Email address exists
        } else {
          await userStore.updateNameEmail(name.value, email.value)
          // if we have an email address, we can send a verification email
        }
        if (email.value && userStore.user.emailVerified === false) {
          loader = loading.show()
          window.localStorage.setItem('emailForSignIn', email.value)
          const functions = getFunctions()
          const resendEmail = httpsCallable(functions, 'sendSignInLinkToEmail')
          await resendEmail({ email: email.value, targetpath: props.targetpath })
          toast.success(i18n.t('registration.passwordless.mail-sent'))
          loader.hide()
        }
      } else {
        // Email not submitted
        await userStore.updateNameEmail(name.value, email.value)
      }
      emits('submit')
    } catch (error) {
      console.error(error)
      if (error.code == 'auth/email-already-in-use') {
        toast.error(i18n.t('registration.email-already-in-use'))
      } else {
        toast.error(i18n.t('common.error'))
      }
      if (loader) {
        loader.hide()
      }
    }
  }
}

const nameIsValid = computed(() => {
  return validationEnabled.value && name.value.length > 2
})

const emailIsValid = computed(() => {
  if (validationEnabled.value) {
    if (props.prevent == 'add-roll') {
      return EmailValidator.validate(email.value)
    } else {
      return !email.value || EmailValidator.validate(email.value)
    }
  }
  return false
})

const updateVisibility = (entries: IntersectionObserverEntry[]) => {
  if (!entries[0].isIntersecting && props.show) {
    showExplanation.value = false
    // we don't turn it on anymore to avoid flickering
  }
}

onMounted(() => {
  if (userStore.user) {
    name.value = userStore.user.displayName
    email.value = userStore.user.email
  }
  loginStore.mergeWithUserId = userStore.user.uid

  // This IntersectionObserver is used to hide the explanation text when there's not enough
  // space because of the Android Keyboard
  observer = new IntersectionObserver(updateVisibility, {
    threshold: 1,
  })

  if (formFieldElement.value) {
    observer.observe(formFieldElement.value)
  }
})

onUnmounted(() => {
  if (observer && formFieldElement.value) {
    observer.unobserve(formFieldElement.value)
  }
})
</script>
<template>
  <div>
    <CommonBottomModal :show="show" @close="$emit('close')">
      <div class="flex w-full justify-between items-center mb-1">
        <CommonIconButton @click="$emit('close')" data-qa="close">
          <Icon icon="mdi:close" />
        </CommonIconButton>
      </div>
      <div class="text-left">
        <div v-if="isGuest">
          <h2 class="w-full text-center font-medium mb-3">
            {{ $t('guest.popup-title') }}
          </h2>
          <div class="flex flex-row">
            <div class="bg-stone-300 grow rounded-2xl m-2 p-4 active:bg-stone-400" data-qa="add-camera-roll">
              <footer-button :label="$t('registration.title')" :active="true" @click="router.push('/register')" />
            </div>
            <div class="bg-stone-300 grow rounded-2xl m-2 p-4 active:bg-stone-400" data-qa="join">
              <footer-button :label="$t('login.login')" :active="true" @click="router.push('/login')"/>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-center font-medium pt-4">
            {{ $t(prevent == 'add-roll' ? 'camera-rolls.add-roll-guard.title' : 'camera-rolls.upload-guard.title') }}
          </div>
          <div ref="formFieldElement" class="w-full">
          <CommonLabelledInput
            v-model="name"
            name="name"
            class="mt-8"
            :has-error="!nameIsValid && validationEnabled"
            :label="$t('profile.name')"
            :mandatory="true"
            position="top"
            :placeholder="$t('profile.name-placeholder')"
          />
          <CommonLabelledInput
            v-model="email"
            name="email"
            :has-error="!emailIsValid && validationEnabled"
            :label="$t('profile.email') + (prevent === 'upload' ? ` (${$t('common.optional')})` : '')"
            :mandatory="prevent !== 'upload'"
            position="bottom"
            :placeholder="$t('profile.email-placeholder')"
          />
            <div v-if="validationEnabled" class="w-full p-2 pb-0">
              <div v-if="!emailIsValid" class="text-sm font-medium text-red-500">
                {{ $t('profile.error-email-is-invalid') }}
              </div>
              <div v-if="!nameIsValid" class="text-sm font-medium text-red-500">
                {{ $t('profile.error-name-is-mandatory') }}
              </div>
            </div>
          </div>
          <div v-if="showExplanation" class="text-sm text-center my-4 text-stone-500">
            {{ $t('camera-rolls.upload-guard.explanation') }}
          </div>
          <CommonButton :dark="true" @click="submitForm" data-qa="upload-guard-button-submit">
            {{ $t(prevent == 'add-roll' ? 'camera-rolls.add-roll-guard.submit' : 'camera-rolls.upload-guard.submit') }}
          </CommonButton>
          <RouterLink to="/login" class="text-sm text-stone-500 mt-4 underline block" data-qa="login-link">
            {{ $t('index.have-account') }}
          </RouterLink>
        </div>
      </div>
    </CommonBottomModal>
  </div>
</template>
