<template>
  <div class="skeleton-box">
    <slot />
  </div>
</template>

<style scoped>
.skeleton-box {
  @apply bg-gray-100;
}
</style>
