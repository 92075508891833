<script setup lang="ts">
import {HydratedPhoto} from '@/types/types.d'
import {PropType, inject, watch} from 'vue'
import {computedAsync} from '@vueuse/core'
import {useGetPhotoUrl} from '~~/composables/usePhotoDisplayTools'
import SkeletonBox from "~/components/Common/Skeleton/SkeletonBox.vue";
import Skeleton from "~/components/Common/Skeleton/Skeleton.vue";

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
  photo: {
    type: Object as PropType<HydratedPhoto>,
    required: true,
  },
  prefix: {
    type: String,
    default: '',
  },
  square: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: String,
    default: 'lazy',
  },
  class: String,
  zooming: {
    type: Boolean,
    default: false
  }
})

const downloadUrl = computedAsync(() => {
  return useGetPhotoUrl(props.prefix, props.photo.fileName, props.photo?.fileType)
})
const libProvidedIsSliding = inject('isSliding')

watch(() => props.zooming, () => {
  if (props.zooming) {
    // lib will cancel all attempts to slide if already in sliding state
    libProvidedIsSliding.value = props.zooming
  } else {
    // wait extra time for zoom restore transformation to finish
    setTimeout(() => {
      libProvidedIsSliding.value = false
    }, 300)
  }
})
</script>
<template>
  <div v-if="square">
    <img
      v-if="downloadUrl"
      :loading="loading"
      v-lazy="{ src: downloadUrl, delay: 100 }"
      class="object-cover w-full cursor-pointer aspect-ratio-1-1"
      data-qa="photo"
      :class="props.class"
      alt=""
    />
    <SkeletonBox v-else class="aspect-ratio-1-1">
      <Skeleton class="w-full h-full"/>
    </SkeletonBox>
  </div>
  <div v-else>
    <img
      v-if="downloadUrl"
      :loading="loading"
      v-lazy="{ src: downloadUrl, delay: 100 }"
      class="cursor-pointer"
      :class="props.class"
      data-qa="photo"
      alt=""
    />
    <SkeletonBox v-else class="aspect-ratio-1-1">
      <Skeleton class="w-full h-full"/>
    </SkeletonBox>
  </div>
</template>

<style scoped>
.aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}
</style>
