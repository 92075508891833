<script setup lang="ts">
defineProps({
  name: {
    type: String,
    default: ""
  },
  modelValue: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const checkboxClick = (event: Event) => {
  const checkbox = event.target as HTMLInputElement
  emit('update:modelValue', checkbox.checked)
}
</script>

<template>
  <label :for="name" class="inline-flex relative items-center cursor-pointer">
    <input type="checkbox" :checked="modelValue" :id="name" class="sr-only peer" @input="checkboxClick">
    <div class="w-11 h-6 peer-focus:outline-none bg-stone-200 rounded-full peer
                after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-black
                after:border-gray-300 after:border-2
                after:rounded-full after:h-5 after:w-5 after:transition-all
                hover:bg-stone-300
                peer-checked:after:translate-x-full peer-checked:bg-black peer-checked:after:border-white
                peer-checked:after:bg-white">
    </div>
  </label>
</template>
